import { MemberInfo, TideClientConfig } from "@qite/tide-client/build/types";
import { JwtPayload, jwtDecode } from "jwt-decode";

interface MemberInfoPayload extends JwtPayload {
  company: string;
  language: string;
  member: string;
  email: string;
  unique_name: string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid": string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress": string;
}

export const decodeTokenToMemberInfo = (token: string): MemberInfo | undefined => {
  if (!token) {
    return undefined;
  }

  const decodedToken = jwtDecode<MemberInfoPayload>(token);
  let member: MemberInfo | undefined = undefined;
  // check if token is not expired
  if (decodedToken?.exp && decodedToken.exp * 1000 < new Date().getTime()) {
    return undefined;
  }

  if (decodedToken) {
    member = {
      id: decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"]
        ? parseInt(decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"])
        : 0,
      companyId: decodedToken.company ? parseInt(decodedToken.company) : 0,
      name: decodedToken.unique_name,
      email: decodedToken.email || decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"],
      language: decodedToken.language,
      agentId: decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"]
        ? parseInt(decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"])
        : 0,
    } as MemberInfo;
  }
  return member;
};

export const getTideClientConfig = (): TideClientConfig => {
  const host = process.env.GATSBY_TIDE_HOST;
  const apiKey = process.env.GATSBY_TIDE_API_KEY;

  if (!host || !apiKey) {
    throw new Error("Tide host or API key not provided.");
  }

  const token = getToken();

  return {
    host,
    apiKey,
    token
  };
};

const getToken = (): string | undefined => {
  if (typeof sessionStorage !== "undefined") {
    return sessionStorage.getItem("token") || undefined;
  }

  return undefined;
};
