import { get, isNil, kebabCase } from "lodash";
import { TideItem, TideItemForSeizoenToernooi, TideItemForSeizoenWedstrijd, TideItemForSporten, TideItemForTeam, TideItemForToernooi, TideItemForWedstrijd } from "../types";

export const getChildItemsWithTypename = <T extends TideItem>(
  typename: string,
  item?: TideItem
): T[] | undefined => {
  if (isNil(item) || isNil(item.childItems)) {
    return undefined;
  }

  return item.childItems
    .filter((item) => get(item, "__typename") === typename)
    .map((item) => item as T);
};

export const getChildItemsInTypenameArray = <T extends TideItem>(
  typenames: string[],
  item?: TideItem
): T[] | undefined => {
  if (isNil(item) || isNil(item.childItems)) {
    return undefined;
  }

  return item.childItems
    .filter((item) => typenames.includes(get(item, "__typename")))
    .map((item) => item as T);
};

export const getParentItemWithTypename = <T extends TideItem>(
  typename: string,
  item?: TideItem
): T | undefined => {
  if (isNil(item) || isNil(item.parentItem)) {
    return undefined;
  }

  if (get(item.parentItem, "__typename") !== typename) {
    return undefined;
  }

  return item.parentItem as T;
};

export const getSpecificChildItemWithName = <T extends TideItem>(
  name: string,
  item?: TideItem): T | undefined => {
  if (isNil(item) || isNil(item.childItems)) {
    return undefined;
  }

  const childItem = item.childItems
    .find((item) => item?.name === name);
  return childItem as T;
};

export const parse = function <T>(item: T | undefined | null) {
  if (item === null) {
    return undefined;
  }
  return item;
};

export const findGameById = (games: TideItemForWedstrijd[], id: string | undefined) => {
  if (!id) {
    return undefined;
  }
  return games.find((g) =>
    (getChildItemsWithTypename("TideItemForSeizoenWedstrijd", g) as TideItemForSeizoenWedstrijd[]).some(
      (sg) => sg.content?.general?.id === id
    )
  );
};

export const findSeasonGameById = (game: TideItemForWedstrijd | undefined, id: string | undefined) => {
  if (!id || !game) {
    return undefined;
  }
  return (getChildItemsWithTypename("TideItemForSeizoenWedstrijd", game) as TideItemForSeizoenWedstrijd[]).find(
    (sg) => sg.content?.general?.id === id
  );
};

export const findTournamentById = (tournaments: TideItemForToernooi[], id: string | undefined | null) => {
  if (!id) {
    return undefined;
  }
  return tournaments.find((t) =>
    (getChildItemsWithTypename("TideItemForSeizoenToernooi", t) as TideItemForSeizoenToernooi[]).some(
      (st) => st.content?.general?.id === id
    )
  );
};

export const findTournamentByTagId = (tournaments: TideItemForToernooi[], id: number | undefined | null) => {
  if (!id) {
    return undefined;
  }
  return tournaments.find((t) => t.content?.general?.tag?.tideId === id);
};

export const getSportName = (item: TideItemForTeam | TideItemForToernooi | TideItemForWedstrijd) => {
  let sportItem = getParentItemWithTypename("TideItemForSporten", item) as TideItemForSporten;
  if (!sportItem && item.parentItem) {
    sportItem = getParentItemWithTypename("TideItemForSporten", item.parentItem) as TideItemForSporten;
  }
  return sportItem?.name;
};

export const getSportPath = (item: TideItemForTeam | TideItemForToernooi | TideItemForWedstrijd) => {
  let sportItem = getParentItemWithTypename("TideItemForSporten", item) as TideItemForSporten;
  if (!sportItem && item.parentItem) {
    sportItem = getParentItemWithTypename("TideItemForSporten", item.parentItem) as TideItemForSporten;
  }
  return (sportItem?.content?.general?.path ?? kebabCase(sportItem?.name)) + '/';
};
