import { uniq } from "lodash";
import React, { createContext, useState } from "react";
import {
  TideItemForBlogPage,
  TideItemForBlogPageConnection,
  TideItemForCountry,
  TideItemForCountryConnection,
  TideItemForNavigationSection,
  TideItemForNotificationComponent,
  TideItemForRating,
  TideItemForTeam,
  TideItemForTeamConnection,
  TideItemForToernooi,
  TideItemForToernooiConnection,
  TideItemForWedstrijd,
  TideItemForWedstrijdConnection,
  TideSalesUnit,
  TideSalesUnitConnection,
} from "../../types";
import { getSportName } from "../../utils/component";

export const soccerTemplateArray = ["soccer", "voetbal"];

// Define the props interface
interface ComponentProviderProps {
  children: React.ReactNode;
}

interface GlobalData {
  primaryNavigationSection: TideItemForNavigationSection;
  footerNavigationSection: TideItemForNavigationSection;
  socialNavigationSection: TideItemForNavigationSection;
  quickLinksSection: TideItemForNavigationSection;
  notificationBar: TideItemForNotificationComponent;
  kiyohRating: TideItemForRating;
  teamComponents: TideItemForTeamConnection;
  tournamentComponents: TideItemForToernooiConnection;
  gameComponents: TideItemForWedstrijdConnection;
  blogComponents: TideItemForBlogPageConnection;
  countryComponents: TideItemForCountryConnection;
  salesUnits: TideSalesUnitConnection;
}

export interface ComponentContextType {
  primaryNavigation?: TideItemForNavigationSection;
  footerNavigation?: TideItemForNavigationSection;
  socialNavigation?: TideItemForNavigationSection;
  quickLinksNavigation?: TideItemForNavigationSection;
  notificationBar?: TideItemForNotificationComponent;
  kiyohRating?: TideItemForRating;
  teams: TideItemForTeam[];
  tournaments: TideItemForToernooi[];
  games: TideItemForWedstrijd[];
  blogs: TideItemForBlogPage[];
  countries: TideItemForCountry[];
  catalogueIds: number[];
  // setTeams: (teams: TideItemForTeam[]) => void;
  // setTournaments: (teams: TideItemForToernooi[]) => void;
  // setGames: (teams: TideItemForWedstrijd[]) => void;
  // setBlogs: (blogs: TideItemForBlogPage[]) => void;
  // setCountries: (blogs: TideItemForCountry[]) => void;
  setGlobalData: () => Promise<void>;
  // setPrimaryNavigation: (primaryNavigation: TideItemForNavigationSection) => void;
  // setFooterNavigation: (footerNavigation: TideItemForNavigationSection) => void;
  // setSocialNavigation: (socialNavigation: TideItemForNavigationSection) => void;
  // setNotificationBar: (notificationBar: TideItemForNotificationComponent) => void;
}

const initialState: ComponentContextType = {
  primaryNavigation: undefined,
  footerNavigation: undefined,
  socialNavigation: undefined,
  quickLinksNavigation: undefined,
  notificationBar: undefined,
  kiyohRating: undefined,
  teams: [],
  tournaments: [],
  games: [],
  blogs: [],
  countries: [],
  catalogueIds: [],
  // setTeams: () => {},
  // setTournaments: () => {},
  // setGames: () => {},
  // setBlogs: () => {},
  // setCountries: () => {},
  setGlobalData: async () => {},
  // setPrimaryNavigation: () => {},
  // setFooterNavigation: () => {},
  // setSocialNavigation: () => {},
  // setNotificationBar: () => {},
};

export const ComponentContext = createContext<ComponentContextType>(initialState);

export const ComponentProvider: React.FC<ComponentProviderProps> = ({ children }) => {
  const [primaryNavigation, setPrimaryNavigation] = useState<TideItemForNavigationSection>();
  const [footerNavigation, setFooterNavigation] = useState<TideItemForNavigationSection>();
  const [socialNavigation, setSocialNavigation] = useState<TideItemForNavigationSection>();
  const [quickLinksNavigation, setQuickLinksNavigation] = useState<TideItemForNavigationSection>();
  const [notificationBar, setNotificationBar] = useState<TideItemForNotificationComponent>();
  const [kiyohRating, setKiyohRating] = useState<TideItemForRating>();
  const [teams, setTeams] = useState<TideItemForTeam[]>([]);
  const [tournaments, setTournaments] = useState<TideItemForToernooi[]>([]);
  const [games, setGames] = useState<TideItemForWedstrijd[]>([]);
  const [blogs, setBlogs] = useState<TideItemForBlogPage[]>([]);
  const [countries, setCountries] = useState<TideItemForCountry[]>([]);
  const [catalogueIds, setCatalogueIds] = useState<number[]>([]);

  const setGlobalData = async () => {
    const response = await fetch(`/globalData-${process.env.GATSBY_WEBSITE}.json`);
    const json = (await response.json()) as GlobalData;

    setPrimaryNavigation(json.primaryNavigationSection);
    setFooterNavigation(json.footerNavigationSection);
    setSocialNavigation(json.socialNavigationSection);
    setQuickLinksNavigation(json.quickLinksSection);
    setNotificationBar(json.notificationBar);
    setKiyohRating(json.kiyohRating);

    const salesUnits = json.salesUnits.nodes as TideSalesUnit[];
    const salesUnit = salesUnits.find((n) => n.tideId === parseInt(process.env.SALESUNIT ?? "0", 10));

    if (salesUnit && salesUnit.catalogueIds) {
      setCatalogueIds(salesUnit.catalogueIds.map((c) => c as number));
    }

    const filteredTeams =
      process.env.GATSBY_WEBSITE === "Sportreizen"
        ? json.teamComponents.nodes.filter((team) => getSportName(team) && !soccerTemplateArray.includes(getSportName(team).toLowerCase()))
        : json.teamComponents.nodes.filter((team) => getSportName(team) && soccerTemplateArray.includes(getSportName(team).toLowerCase()));
    // only create a team is qsm for teams with matches
    const homeTeamIds = json.gameComponents.nodes.map((g) => g.content?.general?.homeTeamId);
    const awayTeamIds = json.gameComponents.nodes.map((g) => g.content?.general?.awayTeamId);
    const teamIds = uniq([...homeTeamIds, ...awayTeamIds]);
    setTeams(filteredTeams.filter((team) => teamIds.includes(team.content?.general?.id)));
    const filteredTournaments =
      process.env.GATSBY_WEBSITE === "Sportreizen"
        ? json.tournamentComponents.nodes.filter(
            (tournament) => getSportName(tournament) && !soccerTemplateArray.includes(getSportName(tournament).toLowerCase())
          )
        : json.tournamentComponents.nodes.filter(
            (tournament) => getSportName(tournament) && soccerTemplateArray.includes(getSportName(tournament).toLowerCase())
          );
    setTournaments(filteredTournaments);
    setGames(json.gameComponents.nodes);
    setBlogs(json.blogComponents.nodes);
    setCountries(json.countryComponents.nodes);
  };

  return (
    <ComponentContext.Provider
      value={{
        primaryNavigation,
        footerNavigation,
        socialNavigation,
        quickLinksNavigation,
        notificationBar,
        kiyohRating,
        teams,
        tournaments,
        games,
        blogs,
        countries,
        catalogueIds,
        // setTeams,
        // setTournaments,
        // setGames,
        // setBlogs,
        // setCountries,
        setGlobalData,
        // setPrimaryNavigation,
        // setFooterNavigation,
        // setSocialNavigation,
        // setNotificationBar,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

// export interface ComponentData {
//   // primaryNavigationSection: TideItemForNavigationSection;
//   // footerNavigationSection: TideItemForNavigationSection;
//   // socialNavigationSection: TideItemForNavigationSection;
//   // notificationBar: TideItemForNotificationComponent;
//   teamComponents: TideItemForTeamConnection;
//   tournamentComponents: TideItemForToernooiConnection;
//   gameComponents: TideItemForWedstrijdConnection;
//   blogComponents: TideItemForBlogPageConnection;
//   countryComponents: TideItemForCountryConnection;
// }

// export const fillComponentContext = async (contextType: ComponentContextType) => {
//   const response = await fetch(`/globalData-${process.env.GATSBY_WEBSITE}.json`);
//   const json = (await response.json()) as ComponentData;

//   const filteredTeams =
//     process.env.GATSBY_WEBSITE === "Sportreizen"
//       ? json.teamComponents.nodes.filter((team) => getSportName(team) && getSportName(team).toLowerCase() !== "soccer")
//       : json.teamComponents.nodes.filter((team) => getSportName(team) && getSportName(team).toLowerCase() === "soccer");
//   // only create a team is qsm for teams with matches
//   const homeTeamIds = json.gameComponents.nodes.map((g) => g.content?.general?.homeTeamId);
//   const awayTeamIds = json.gameComponents.nodes.map((g) => g.content?.general?.awayTeamId);
//   const teamIds = uniq([...homeTeamIds, ...awayTeamIds]);
//   contextType.setTeams(filteredTeams.filter((team) => teamIds.includes(team.content?.general?.id)));
//   const filteredTournaments =
//     process.env.GATSBY_WEBSITE === "Sportreizen"
//       ? json.tournamentComponents.nodes.filter((tournament) => getSportName(tournament) && getSportName(tournament).toLowerCase() !== "soccer")
//       : json.tournamentComponents.nodes.filter((tournament) => getSportName(tournament) && getSportName(tournament).toLowerCase() === "soccer");
//   contextType.setTournaments(filteredTournaments);
//   contextType.setGames(json.gameComponents.nodes);
//   contextType.setBlogs(json.blogComponents.nodes);
//   contextType.setCountries(json.countryComponents.nodes);
//   // contextType.setPrimaryNavigation(data.primaryNavigationSection);
//   // contextType.setFooterNavigation(data.footerNavigationSection);
//   // contextType.setSocialNavigation(data.socialNavigationSection);
//   // contextType.setNotificationBar(data.notificationBar);
// };
