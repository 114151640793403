import { MemberInfo } from "@qite/tide-client/build/types";
import React, { createContext, useEffect, useState } from "react";
import { decodeTokenToMemberInfo } from "../../utils/member-and-tide-utils";

interface GlobalProviderProps {
  children: React.ReactNode;
}

export interface GlobalContextType {
  showNotificationBar: boolean;
  language: string;
  qsmActive: boolean;
  filtersActive: boolean;
  isMobileMenuOpen: boolean;
  isBookingPage: boolean;
  affiliate: string;
  member?: MemberInfo;
  flyInIsOpen: boolean;
  setShowNotificationBar: (show: boolean) => void;
  setLanguage: (language: string) => void;
  setQsmActive: (active: boolean) => void;
  setFiltersActive: (active: boolean) => void;
  setMobileMenuOpen: (active: boolean) => void;
  setIsBookingPage: (active: boolean) => void;
  setAffiliate: (affiliateId: string) => void;
  setMember: (member: MemberInfo | undefined) => void;
  setFlyInIsOpen: (isOpen: boolean) => void;
}

const initialState: GlobalContextType = {
  showNotificationBar: true,
  language: "",
  qsmActive: false,
  filtersActive: false,
  isMobileMenuOpen: false,
  isBookingPage: false,
  affiliate: "",
  member: {} as MemberInfo,
  flyInIsOpen: false,
  setShowNotificationBar: () => {},
  setLanguage: () => {},
  setQsmActive: () => {},
  setFiltersActive: () => {},
  setMobileMenuOpen: () => {},
  setIsBookingPage: () => {},
  setAffiliate: () => {},
  setMember: () => {},
  setFlyInIsOpen: () => {},
};

const GlobalContext = createContext(initialState);

export const GlobalProvider: React.FC<GlobalProviderProps> = (props) => {
  const [showNotificationBar, setShowNotificationBar] = useState(true);
  const [language, setLanguage] = useState("");
  const [qsmActive, setQsmActive] = useState(false);
  const [filtersActive, setFiltersActive] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isBookingPage, setIsBookingPage] = useState(false);
  const [affiliate, setAffiliate] = useState("");
  const [member, setMember] = useState<MemberInfo | undefined>();
  const [flyInIsOpen, setFlyInIsOpen] = useState<boolean>(false);

  // let m = "";
  // if (typeof sessionStorage !== "undefined") {
  //   const sessionData = sessionStorage.getItem("currentMember");
  //   if (sessionData) {
  //     m = sessionData;
  //   }
  // }
  useEffect(() => {
    if (typeof sessionStorage !== "undefined") {
      const sessionToken = sessionStorage.getItem("token");
      if (sessionToken) {
        const member = decodeTokenToMemberInfo(sessionToken);
        if (member) {
          setMember(member);
        } else {
          sessionStorage.removeItem("token");
          setMember(undefined);
        }
      }
    }
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        showNotificationBar,
        language,
        qsmActive,
        filtersActive,
        isMobileMenuOpen,
        isBookingPage,
        affiliate,
        member,
        flyInIsOpen,
        setShowNotificationBar,
        setLanguage,
        setQsmActive,
        setFiltersActive,
        setMobileMenuOpen,
        setIsBookingPage,
        setAffiliate,
        setMember,
        setFlyInIsOpen,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
