exports.components = {
  "component---src-templates-blog-page-template-index-tsx": () => import("./../../../src/templates/blog-page-template/index.tsx" /* webpackChunkName: "component---src-templates-blog-page-template-index-tsx" */),
  "component---src-templates-booking-page-template-index-tsx": () => import("./../../../src/templates/booking-page-template/index.tsx" /* webpackChunkName: "component---src-templates-booking-page-template-index-tsx" */),
  "component---src-templates-country-page-template-index-tsx": () => import("./../../../src/templates/country-page-template/index.tsx" /* webpackChunkName: "component---src-templates-country-page-template-index-tsx" */),
  "component---src-templates-event-page-template-index-tsx": () => import("./../../../src/templates/event-page-template/index.tsx" /* webpackChunkName: "component---src-templates-event-page-template-index-tsx" */),
  "component---src-templates-faq-page-template-index-tsx": () => import("./../../../src/templates/faq-page-template/index.tsx" /* webpackChunkName: "component---src-templates-faq-page-template-index-tsx" */),
  "component---src-templates-login-page-template-index-tsx": () => import("./../../../src/templates/login-page-template/index.tsx" /* webpackChunkName: "component---src-templates-login-page-template-index-tsx" */),
  "component---src-templates-page-template-index-tsx": () => import("./../../../src/templates/page-template/index.tsx" /* webpackChunkName: "component---src-templates-page-template-index-tsx" */),
  "component---src-templates-tag-page-template-index-tsx": () => import("./../../../src/templates/tag-page-template/index.tsx" /* webpackChunkName: "component---src-templates-tag-page-template-index-tsx" */),
  "component---src-templates-team-page-template-index-tsx": () => import("./../../../src/templates/team-page-template/index.tsx" /* webpackChunkName: "component---src-templates-team-page-template-index-tsx" */),
  "component---src-templates-tournament-page-template-index-tsx": () => import("./../../../src/templates/tournament-page-template/index.tsx" /* webpackChunkName: "component---src-templates-tournament-page-template-index-tsx" */)
}

