import React from "react";
import { Script } from "gatsby";
import { GlobalProvider } from "./src/contexts/global-provider";
import { ComponentProvider } from "./src/contexts/component-provider";

export const wrapRootElement = ({ element }: any) => (
  <GlobalProvider>
    <ComponentProvider>{element}</ComponentProvider>
  </GlobalProvider>
);

// export const onRenderBody = ({ setPostBodyComponents }) => {
//   const isVoetbalReizen = process.env.GATSBY_WEBSITE === "voetbalreizen";
//   const isSportReizen = process.env.GATSBY_WEBSITE === "sportreizen";
//   setPostBodyComponents([
//     <Script
//       key="gtag-consent"
//       dangerouslySetInnerHTML={{
//         __html: `
//               window.dataLayer = window.dataLayer || [];
//               function gtag(){
//                 dataLayer.push(arguments);
//               }
//               gtag('consent', 'default', {
//                 'ad_storage': 'denied',
//                 'ad_user_data': 'denied',
//                 'ad_personalization': 'denied',
//                 'analytics_storage': 'denied',
//                 'ad_user_data': 'denied',
//                 'ad_personalization': 'denied',
//               });
//             `,
//       }}
//     />,
//     isVoetbalReizen && (
//       <Script
//         key="gtm-voetbalreizen"
//         dangerouslySetInnerHTML={{
//           __html: `
//           (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//           new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//           j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//           'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//           })(window,document,'script','dataLayer','GTM-5WVJFS');
//         `,
//         }}
//       />
//     ),
//     isSportReizen && (
//       <Script
//         key="gtm-sportreizen"
//         dangerouslySetInnerHTML={{
//           __html: `
//                   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//                   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//                   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//                   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//                   })(window,document,'script','dataLayer','GTM-T2LXQGG');
//                 `,
//         }}
//       />
//     ),
//   ]);
// };
